import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-session-reports',
  templateUrl: './session-reports.component.html',
  styleUrls: ['./session-reports.component.scss']
})
export class SessionReportsComponent implements OnInit {

  constructor(private dataService: DataService) { }


  data:any = {status:true, data:[], count:0}
  consultants:any = [];

  limit:any = 20;
  offset:any = 0;
  patient_id:any = "";
  consultant_id:any = "";
  date:any = "";

  ngOnInit() {
    this.loadData();
    this.loadConsultants();
  }

  loadData(){
    this.dataService.getGenericSessionReports(this.patient_id, this.consultant_id, this.date, this.limit, this.offset).subscribe( (data: any[] )=> {
      this.data = data;
      
    });
  }

  loadConsultants(){
    this.dataService.getAllConsultants().subscribe( (data: any[] )=> {
      this.consultants = data;
    });
  }

  runFilter(){
    this.offset = 0;
    this.loadData();
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
    }

    this.loadData();

  }

}
